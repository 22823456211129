import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Background`}</h2>
    <p>{`Grammar is the core structure of a language. It is composed of rules that govern how words can be put together to form sentences. Grammar exists to make communication easier. It’s a way to standardize how we use the language, which cuts down on communication errors.`}</p>
    <h2>{`Capitalization`}</h2>
    <p>{`Title case capitalizes the first letter of every word except articles, prepositions, and conjunctions. Sentence case capitalizes the first letter of the first word. Use capital for names and terms. In Indonesian language, capitalize the correct pronouns. Click here to see the full EYD guidelines.`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" aspectRatio="1:2" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/grammars/capitalization-1.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" aspectRatio="1:2" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/grammars/capitalization-2.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <h2>{`Apostrophe`}</h2>
    <p>{`A mark to show possession. If the word is singular, you must add an apostrophe and an s, even after s, x, or z. For plural nouns that end in s, add an apostrophe. NEVER use apostrophes for possessive pronouns or it.`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/grammars/aphostrope-1.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/grammars/aphostrope-2.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <h2>{`Quotation Marks`}</h2>
    <p>{`Quotation marks draw attention to words and reference specific titles. Use double quotation marks (” ”) in most content. For quotes within quotes, use single quotation notes (‘ ‘). NEVER use quotation marks for indirect statements, or put for emphasis some new products/features, etc.`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" aspectRatio="1:2" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/grammars/quotation-1.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" aspectRatio="1:2" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/grammars/quotation-2.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <h2>{`Colons`}</h2>
    <p>{`Use colon at the end of a phrase to unveil a list, examples, contrasts, and block quotes with more than 3 items. Colon is used to start item listing, especially for more than 3 item in numbering/bullet points format. Colon is used for data or profile resume, especially in purchase, booking or registration summary.`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" aspectRatio="1:2" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/grammars/colon-1.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" aspectRatio="1:2" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/grammars/colon-2.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <h2>{`Commas`}</h2>
    <p>{`Comma is used to separate list of maximum three items, emphasize purpose of an action for user, and used after exclamation or expression words.`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" aspectRatio="3:4" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/grammars/commas-1.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" aspectRatio="3:4" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/grammars/commas-2.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <h2>{`Range, Spans, & Dashes`}</h2>
    <p>{`Hyphen (-) to explains spans and ranges in numbers, replacing ‘from’ or ‘to’, used in indonesian date format, and when foreign or ethnic words are used in Indonesian sentences.`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/grammars/range-1.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/grammars/range-2.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <h2>{`Ellipses`}</h2>
    <p>{`Use ellipses to show that an action is in progress and to shorten words when the text gets too long.`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/grammars/elipsis-1.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/grammars/elipsis-2.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <h2>{`Periods`}</h2>
    <p>{`End all sentences with a period. Put one space, after a period. Use period everywhere except for headlines, headings, subheadings, UI titles, and buttons.`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/grammars/periods-1.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/grammars/periods-2.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <h2>{`Question Marks`}</h2>
    <p>{`All direct questions and requests need to end with question mark. When a customer needs to make a decision, a question is also needed. Put one space, after a question mark.`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/grammars/question-1.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/grammars/question-2.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <h2>{`Exclamation Points`}</h2>
    <p>{`Used to emphasize things and also emotions. Preferably used in an informal context and content. Never use exclamation points in failure messages or alerts.`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/grammars/exclamation-1.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/grammars/exclamation-2.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <h2>{`Pronouns & Referring to Product`}</h2>
    <p>{`Used to address a user in a personalized experience. Refer to a product as “we” and “our”, but avoid inserting the product into the content as much as possible.`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/grammars/pronouns-1.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/grammars/pronouns-2.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      